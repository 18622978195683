import { createSelector } from 'reselect';
import { requestSubscribe, requestCurrentUser } from 'actions/users';
import { requestActivateAccount } from 'actions/activateAccount';
import { user as constUser } from 'constants/index';
import { isEqual, sortBy } from 'lodash';
import createLoadingSelector from './create-loading-selector';

export const users = (state) => state.users;

export const getUserOneTimeLoginLink = createSelector(
  users,
  (data) => data.oneTimeLogIn
);

export const getUserData = createSelector(users, (data) => data.currentUser);

export const getCurrentUser = createSelector(users, (data) =>
  data.currentUser && !data.currentUser.guest
    ? {
        ...data.currentUser,
        isPatient: data.currentUser.userType === constUser.PATIENT,
        isGroupUser: data.currentUser.userType === constUser.GROUP_USER,
        isPortalUser: data.currentUser.userType === constUser.PORTAL_USER,
        isCmsUser: data.currentUser.userType === constUser.CMS_USER,
      }
    : data.currentUser
);

export const getIsGuestUser = createSelector(
  getCurrentUser,
  (user) => user === null || user.guest === true
);

export const getCompanyUserCompanies = createSelector(
  users,
  (response) => response.companyUserCompanies
);

export const getCurrentCompanyInfo = createSelector(
  getCompanyUserCompanies,
  (companies) => {
    const currentCompany = companies?.companiesList.find(
      (i) => i.id === companies.publicPortalId
    );

    const currentPricingGroup = currentCompany?.pricingGroups.find(
      (i) => i.id === companies.currentPricingGroups
    );

    return { currentCompany, currentPricingGroup };
  }
);

export const getIsGroupUser = createSelector(
  getCurrentUser,
  (user) => user?.userType === constUser.GROUP_USER
);

export const getIsCmsUser = createSelector(
  getCurrentUser,
  (user) => user?.userType === constUser.CMS_USER
);

export const getIsAdmin = createSelector(
  getCurrentUser,
  (user) => user?.isAdmin
);

export const getIsProxyUser = createSelector(
  getCurrentUser,
  (user) => user?.isProxyUser
);

export const getIsReportUser = createSelector(
  getCurrentUser,
  (user) => user?.userType === constUser.REPORT_USER
);

export const getIsPortalUser = createSelector(
  getCurrentUser,
  (user) => user?.userType === constUser.PORTAL_USER
);

export const getIsCallCenterProxyUser = createSelector(
  getCurrentUser,
  getIsGroupUser,
  getIsPortalUser,
  (user, isGroupUser, isPortalUser) =>
    user?.isProxyUser && !isGroupUser && !isPortalUser
);

export const getIsProxyGroupUser = createSelector(getCurrentUser, (user) =>
  Boolean(user?.isProxyGroupUser)
);

export const getIsPatientUser = createSelector(
  getCurrentUser,
  (user) => user?.userType === constUser.PATIENT
);

export const getIsDirectUser = createSelector(
  getIsCmsUser,
  getIsGroupUser,
  (isCmsUser, isGroupUser) => isCmsUser || isGroupUser
);

export const getSubscribe = createSelector(users, (data) => data.subscribed);

export const getTabsByUserType = createSelector(
  users,
  (data) => data.tabsByUserType
);

export const checkUserByPersonalInformation = createSelector(
  users,
  (data) => data.checkUserByPersonalInformation
);

export const notCompleteProfile = createSelector(
  getCurrentUser,
  (user) => user?.notCompleteProfile === true
);

export const getShowDirectNavbar = createSelector(
  getIsDirectUser,
  notCompleteProfile,
  getIsReportUser,
  (user, showCompleteProfile, recUser) => user || showCompleteProfile || recUser
);

export const getPermissions = createSelector(
  getCurrentUser,
  (user) => user.permissions
);

export const getShowModalByUser = createSelector(
  getCurrentUser,
  (user) => user?.modalsInfo
);

export const getGoodFaithEnabled = createSelector(
  getUserData,
  (data) => data.goodFaithEstimate
);

export const getCurrentUserLoading = createLoadingSelector(
  requestCurrentUser.actionName
)();

export const getSubscribeLoading = createLoadingSelector(
  requestSubscribe.actionName
)();

export const getActivateAccount = createSelector(
  users,
  (data) => data.activateAccount
);

export const getCheckSsoDomain = createSelector(users, (data) => data.sso);

export const getHomePageUrl = createSelector(getCurrentUser, (user) => {
  const url = user?.homePageUrl || '/';
  return url === '/' ? { to: url } : { href: url };
});

export const createActivateAccountLoading = createLoadingSelector(
  requestActivateAccount.actionName
)();
